import { FilterRule, OperatorType } from '@amzn/claritygqllambda';
import { FilteredAttributesConfig } from '@clarity-website/config/domain-configs';
import { AttributeGroup } from '@clarity-website/maintenance/form/common/Constants';
import {
  FilterChild,
  FilterData,
} from '@clarity-website/reports/edit/report-edit-types';
import {
  ChildFilterInterface,
  REPORT_FILTER_SEPARATOR,
} from '@clarity-website/reports/filters/filter-types';
import {
  ReportFilter,
  ReportFilterRule,
  ReportFilterV2,
} from '@clarity-website/reports/report-types';
import { ReportTemplate } from '@clarity-website/reports/useFetchRawReport';
import { groupBy, prop, sortBy } from 'ramda';

interface GetSubFilterParams {
  filter: ReportFilterRule[];
  leaderAttributes: (string | undefined)[];
  simReportsToAttributeIds?: string[];
  isGettingLeaderFilter: boolean;
}

export function generateFilterIdStr(
  filterValue: string,
  attrId: string,
  included = true,
) {
  return [filterValue, attrId, included].join(REPORT_FILTER_SEPARATOR);
}

function getFilterIdStringFromOperator(
  filterRule: ReportFilterRule,
  filteredAttributesConfig?: FilteredAttributesConfig,
) {
  if (filterRule.In) {
    const filter = filterRule.In;
    let attributeId = Object.keys(filter)[0];
    const filterValues = filter[attributeId];
    attributeId =
      attributeId === filteredAttributesConfig?.primaryLeaderInDirectsAttrId
        ? filteredAttributesConfig?.primaryLeaderAttrId
        : attributeId;
    return filterValues.map((value) => generateFilterIdStr(value, attributeId));
  }
  if (filterRule.Not_In) {
    const filter = filterRule.Not_In;
    const attributeId = Object.keys(filter)[0];
    const filterValues = filter[attributeId];
    if (
      filteredAttributesConfig?.simReportsToAttributeIds.includes(attributeId)
    ) {
      return attributeId ===
        filteredAttributesConfig.simReportsToAttributeIds[0]
        ? filterValues.map((value) =>
            generateFilterIdStr(
              value,
              filteredAttributesConfig.directManagerAttrId ||
                filteredAttributesConfig.directManagerAttrIdGQL,
              false,
            ),
          )
        : [];
    }
    return filterValues.map((value) =>
      generateFilterIdStr(value, attributeId, false),
    );
  }
  return [];
}

function recursivelyFlattenFilters(
  filters: ReportFilterRule[],
  operator: OperatorType,
  filteredAttributesConfig?: FilteredAttributesConfig,
): string[] {
  if (operator === OperatorType.And || operator === OperatorType.Or) {
    return filters.flatMap((operatorObject: any) => {
      const operator = Object.keys(operatorObject)[0] as OperatorType;
      if (operator === OperatorType.In || operator === OperatorType.NotIn) {
        return getFilterIdStringFromOperator(
          operatorObject,
          filteredAttributesConfig,
        );
      }
      if (operator === OperatorType.And || operator === OperatorType.Or) {
        return recursivelyFlattenFilters(
          operatorObject[operator],
          operator,
          filteredAttributesConfig,
        );
      }
      return [];
    });
  }
  return [];
}

export function flattenV2Filters(
  filters: ReportFilterV2,
  filteredAttributesConfig?: FilteredAttributesConfig,
) {
  return recursivelyFlattenFilters(
    filters[OperatorType.And] || [],
    OperatorType.And,
    filteredAttributesConfig,
  );
}

export const getFilterDataForRowLevelReports = (
  filterDataArr: FilterData[],
  editState: string,
): FilterData[] => {
  const [nonMetricArr, metricArr] = filterDataArr.reduce<FilterData[][]>(
    ([nonMetricAttrs, metricAttrs], item) => {
      if (item.key !== AttributeGroup.Metric) {
        return [[...nonMetricAttrs, item], metricAttrs];
      }
      return [nonMetricAttrs, [...metricAttrs, item]];
    },
    [[], []],
  );

  const metricAttrsGroupBySubGroup = Object.entries(
    // clarity crashes if subgroup is empty when it attempts a groupBy
    groupBy<FilterChild>(prop('subGroup'))(metricArr?.[0]?.children || []),
  ).map(([key, value]) => {
    const defaultKey = key.length ? key : 'Metric';
    const parent = `${defaultKey} Attributes`;
    return {
      key: defaultKey,
      parent,
      children: sortBy<FilterChild>(prop('child'))(value),
    };
  });

  if (editState === 'metricColumns') {
    return metricAttrsGroupBySubGroup;
  }
  if (editState === 'attributes') {
    return nonMetricArr;
  }
  if (editState === 'filters') {
    return [...nonMetricArr, ...metricAttrsGroupBySubGroup];
  }
  return filterDataArr;
};

export function translateFiltersToFiltersV2(filters: ReportFilter[]) {
  if (!filters || !filters.length) {
    return undefined;
  }
  return filters.reduce<ReportFilterV2>(
    (acc, { attributeId, operator, values }) => {
      return {
        ...acc,
        And: [...acc.And, { [operator]: { [attributeId]: values } }],
      };
    },
    { And: [] },
  );
}

export function getReportTemplateFilters(
  reportTemplate: ReportTemplate,
  filteredAttributesConfig?: FilteredAttributesConfig,
) {
  if (reportTemplate.filtersV2) {
    try {
      const filtersV2 = JSON.parse(reportTemplate.filtersV2) as ReportFilterV2;
      return flattenV2Filters(filtersV2, filteredAttributesConfig);
    } catch (error) {
      console.error(error);
    }
  }
  const { filters = [] } = reportTemplate;
  return filters.reduce((acc: string[], filter: ChildFilterInterface) => {
    const { values = [], operator, attributeId } = filter;
    if (
      filteredAttributesConfig?.simReportsToAttributeIds?.includes(attributeId)
    ) {
      return [...acc];
    }
    const included = operator === OperatorType.In;
    const items = values.reduce<string[]>((acc, value) => {
      const id = generateFilterIdStr(value, attributeId, included);
      return [...acc, id];
    }, []);
    return [...acc, ...items];
  }, []);
}

export function updateFilterV2WithNewLeaders(
  primaryLeaderLogins: string[],
  currentFilter: ReportFilterRule[],
  leaderAttributes: (string | undefined)[],
): ReportFilterRule[] {
  return currentFilter.reduce((acc: ReportFilterRule[], rule) => {
    if (rule[OperatorType.And]) {
      return [
        ...acc,
        {
          And: updateFilterV2WithNewLeaders(
            primaryLeaderLogins,
            rule.And,
            leaderAttributes,
          ),
        },
      ];
    }
    if (rule[OperatorType.Or]) {
      return [
        ...acc,
        {
          Or: updateFilterV2WithNewLeaders(
            primaryLeaderLogins,
            rule.Or,
            leaderAttributes,
          ),
        },
      ];
    }
    if (rule[OperatorType.In]) {
      const filterKeyValue = rule.In;
      const filterAttribute = Object.keys(filterKeyValue)[0];
      if (leaderAttributes.includes(filterAttribute)) {
        return [...acc, { In: { [filterAttribute]: primaryLeaderLogins } }];
      }
    }

    return [...acc, rule];
  }, []);
}

/**
 * Retrieves either leader or non-leader filters based on the 'isGettingLeaderFilter' parameter.
 * @param filter The filter from which data will be extracted.
 * @param leaderAttributes
 * @param simReportsToAttributeIds
 * @param isGettingLeaderFilter Determines whether to extract leader filters (true) or non-leader filters (false).
 * @returns filters based on the isGettingLeaderFilter' parameter.
 */
export function getSubFilterFromFilterV2({
  filter,
  leaderAttributes,
  simReportsToAttributeIds,
  isGettingLeaderFilter,
}: GetSubFilterParams): FilterRule[] {
  return filter.reduce((acc: FilterRule[], rule) => {
    if (rule[OperatorType.In]) {
      const filterKeyValue = rule.In;
      const filterAttribute = Object.keys(filterKeyValue)[0];
      if (leaderAttributes.includes(filterAttribute) && isGettingLeaderFilter) {
        return [...acc, { In: rule.In }];
      } else if (
        leaderAttributes.includes(filterAttribute) &&
        !isGettingLeaderFilter
      ) {
        return acc;
      }
    }
    if (rule[OperatorType.NotIn]) {
      const filterKeyValue = rule[OperatorType.NotIn];
      const filterAttribute = Object.keys(filterKeyValue)[0];
      if (
        simReportsToAttributeIds?.includes(filterAttribute) &&
        isGettingLeaderFilter
      ) {
        return [...acc, { [OperatorType.NotIn]: rule[OperatorType.NotIn] }];
      } else if (
        simReportsToAttributeIds?.includes(filterAttribute) &&
        !isGettingLeaderFilter
      ) {
        return acc;
      }
    }
    if (rule[OperatorType.And]) {
      return [
        ...acc,
        {
          And: getSubFilterFromFilterV2({
            filter: rule[OperatorType.And],
            leaderAttributes,
            simReportsToAttributeIds,
            isGettingLeaderFilter,
          }),
        },
      ];
    }
    if (rule[OperatorType.Or]) {
      return [
        ...acc,
        {
          Or: getSubFilterFromFilterV2({
            filter: rule[OperatorType.Or],
            leaderAttributes,
            simReportsToAttributeIds,
            isGettingLeaderFilter,
          }),
        },
      ];
    }
    if (isGettingLeaderFilter) {
      return acc;
    }
    return [...acc, rule];
  }, []);
}

export function extractAttributesFromReportTemplate(
  reportTemplate: ReportTemplate | undefined,
) {
  const filtersV2 = JSON.parse(
    reportTemplate?.filtersV2 || '{}',
  ) as ReportFilterV2;

  return (
    flattenV2Filters(filtersV2)?.flatMap(
      (id) => id.split(REPORT_FILTER_SEPARATOR)[1],
    ) || []
  );
}
