import { Domain, PeriodType, TimespanComparison } from '@amzn/claritygqllambda';
import { DomainConfig } from '@clarity-website/config/domain-configs';
import {
  appendPopComparisonToLabel,
  extractPoPComparisonFromLabel,
} from '@clarity-website/reports/edit/timespan/commonUtils';
import {
  generateRollingDatesSelectionConfigs,
  getTimeUnitRanges,
  timeUnitDisplayValues,
} from '@clarity-website/reports/edit/timespan/constants';
import {
  InputError,
  RollingTimespanData,
  TimeAnchor,
  TimePresence,
  TimeUnit,
} from '@clarity-website/reports/edit/timespan/types';
import { TimespanInterface } from '@clarity-website/reports/filters/filter-types';
import {
  DurationType,
  TemporalAdjuster,
  TemporalAdjusterFunction,
  TimespanSchema,
  TimespanType,
  extractIdFromTimespan,
} from '@clarity-website/reports/filters/timespan-config';

export const checkError = (
  { timeUnit, value, timePresence, anchor }: RollingTimespanData,
  domain: string,
): InputError => {
  const timeUnitRanges = getTimeUnitRanges(timePresence, anchor, domain);
  const maxValue = timeUnitRanges[timeUnit];
  if (timePresence === TimePresence.Current) {
    return { isError: false };
  }
  if (value && (!maxValue || value <= maxValue) && value > 0) {
    return { isError: false };
  }
  if (!value || value <= 0) {
    return {
      isError: true,
      message: `Oops, that entry is invalid. Enter a number between 1 - ${maxValue} for ${timeUnitDisplayValues[timeUnit]}`,
    };
  }
  return {
    isError: true,
    message: `Oops, that entry is too high. Enter a number between 1 - ${maxValue} for ${timeUnitDisplayValues[timeUnit]} ${anchor}`,
  };
};

export const timespanIdToRollingTimespanData = (
  timespanId: string,
  domain: string,
): RollingTimespanData => {
  const { name: label } = buildRollingTimespanFromId(timespanId, domain);
  return timespanLabelToRollingTimespanData(label);
};

export const timespanLabelToRollingTimespanData = (label: string) => {
  const rollingTimespan: RollingTimespanData = {
    timeUnit: TimeUnit.Years,
    timePresence: TimePresence.Next,
    anchor: TimeAnchor.CurrentYear,
  };

  // label example: 'Last 3 Months as of 2 years prior, MoM and YoY comparison'
  const [labelDate = '', labelComparisons = ''] = label.split(', '); // following example: ['Last 3 Months as of 2 years prior', 'MoM and YoY comparison']
  const [labelTimePresence = '', labelValue = '1'] = labelDate.split(' '); // following example: ['Last', '3']
  let labelRollingDate = labelDate.replace(labelTimePresence, ''); // following example: ' 3 Months as of 2 years prior'

  const lastTwoYears = 'as of 2 years prior';
  const lastOneYear = 'as of prior year';
  if (labelRollingDate.endsWith(lastTwoYears)) {
    rollingTimespan.anchor = TimeAnchor.TwoYears;
    labelRollingDate = labelRollingDate.replace(lastTwoYears, ''); // following example: ' 3 Months '
  } else if (labelRollingDate.endsWith(lastOneYear)) {
    rollingTimespan.anchor = TimeAnchor.Year;
    labelRollingDate = labelRollingDate.replace(lastOneYear, ''); // following example: ' 3 Months '
  } else {
    rollingTimespan.anchor = TimeAnchor.CurrentYear;
  }

  rollingTimespan.timePresence = labelTimePresence as TimePresence;

  if (rollingTimespan.timePresence !== TimePresence.Current) {
    rollingTimespan.value = parseInt(labelValue); // following example: '3'
    labelRollingDate = labelRollingDate.replace(labelValue, ''); // following example: '  Months '
  }

  // At this point `labelRollingDate` will be a string containing the TimeUnit related text and some residual spaces from previous replacements
  rollingTimespan.timeUnit = labelToTimeUnit(labelRollingDate.trim()); // following example: 'Months'

  rollingTimespan.comparisons = extractPoPComparisonFromLabel(labelComparisons); // following example: 'MoM and YoY comparison'

  return rollingTimespan;
};

const labelToTimeUnit = (s: string): TimeUnit => {
  const map: { [key: string]: TimeUnit } = {
    Hour: TimeUnit.Hour,
    Hours: TimeUnit.Hours,
    Day: TimeUnit.Day,
    Days: TimeUnit.Days,
    'Calendar Week': TimeUnit.CalendarWeek,
    'Calendar Weeks': TimeUnit.CalendarWeek,
    Weeks: TimeUnit.Weeks,
    WTD: TimeUnit.WeekToDate,
    Months: TimeUnit.Months,
    MTD: TimeUnit.MonthToDate,
    TTM: TimeUnit.Ttm,
    T3M: TimeUnit.T3m,
    T6M: TimeUnit.T6m,
    Quarters: TimeUnit.Quarters,
    QTD: TimeUnit.QuarterToDate,
    Years: TimeUnit.Years,
    YTD: TimeUnit.YearToDate,
    'Performance Years': TimeUnit.PerformanceYears,
    'Performance YTD': TimeUnit.PerformanceYearToDate,
    'Performance Months': TimeUnit.PerformanceMonths,
    'Performance MTD': TimeUnit.PerformanceMonthToDate,
    'Performance Quarters': TimeUnit.PerformanceQuarters,
    'Performance QTD': TimeUnit.PerformanceQuarterToDate,
  };
  return map[s] ?? TimeUnit.Hour;
};

export const periodTypeToDefaultTimespan = (
  periodType: PeriodType,
  domain: string,
  domainConfig: DomainConfig,
) => {
  const timeUnitByPeriodType: Record<string, TimeUnit> = {
    [PeriodType.Intraday]: TimeUnit.Hours,
    [PeriodType.Daily]: TimeUnit.Days,
    [PeriodType.Weekly]: TimeUnit.Weeks,
    [PeriodType.Monthly]: TimeUnit.Months,
    [PeriodType.Pm]: TimeUnit.PerformanceMonths,
    [PeriodType.Quarterly]: TimeUnit.Quarters,
    [PeriodType.Yearly]: TimeUnit.Years,
    [PeriodType.Py]: TimeUnit.PerformanceYears,
    [PeriodType.Pq]: TimeUnit.PerformanceYears,
    [PeriodType.Ytd]: TimeUnit.YearToDate,
    [PeriodType.T3m]: TimeUnit.T3m,
    [PeriodType.T6m]: TimeUnit.T6m,
    [PeriodType.Ttm]: TimeUnit.Ttm,
  };

  const timePresence =
    periodType === PeriodType.Ytd ? TimePresence.Current : TimePresence.Last;

  const timeUnit = timeUnitByPeriodType[periodType];
  const value = 1;
  const anchor = TimeAnchor.CurrentYear;
  const timespan = generateRollingDatesSelectionConfigs(domain, domainConfig)?.[
    timePresence
  ]?.[timeUnit]?.timespanSchemaFn(value, anchor);
  if (!timespan) {
    throw new Error(
      `Rolling timespan is undefined timeUnit: ${timeUnit} timePresence: ${timePresence} value: ${value} anchor: ${anchor}`,
    );
  }

  const id = extractIdFromTimespan(timespan);
  return buildRollingTimespanFromId(id, domain);
};

export const generateRollingTimespanSchema = (
  { timeUnit, timePresence, value, anchor, comparisons }: RollingTimespanData,
  domain: string,
  domainConfig?: DomainConfig,
  isSparkchartActive?: boolean,
): TimespanInterface => {
  const timespan = generateRollingDatesSelectionConfigs(domain, domainConfig)?.[
    timePresence
  ]?.[timeUnit]?.timespanSchemaFn(value, anchor, comparisons);
  if (!timespan) {
    throw new Error(
      `Rolling timespan is undefined timeUnit: ${timeUnit} timePresence: ${timePresence} value: ${value} anchor: ${anchor} comparisons: ${comparisons}`,
    );
  }

  const id = extractIdFromTimespan(timespan);
  return {
    ...buildRollingTimespanFromId(id, domain),
    isSparkchartActive: !!isSparkchartActive,
  };
};

const rebuildTemporalAdjusters = (
  temporalAdjustersString: string,
): TemporalAdjuster[] => {
  return temporalAdjustersString
    .split('#')
    .map<TemporalAdjuster>((temporalAdjuster) => {
      const [adjusterFunction, ...rest] = temporalAdjuster.split('^');
      return {
        function: adjusterFunction as TemporalAdjusterFunction,
        arguments: rest[0] ? rest : [],
      };
    });
};

export const rebuildComparisons = (
  comparisonsString?: string,
): TimespanComparison[] | undefined => {
  return comparisonsString
    ?.split('#')
    .reduce((acc: TimespanComparison[], next) => {
      const [comparison, comparisonType] = next.split('^');
      return [
        ...acc,
        {
          comparison,
          comparisonType,
        } as TimespanComparison,
      ];
    }, []);
};

export const buildRollingTimespanFromId = (
  timespanId: string,
  domain: string,
): TimespanInterface => {
  const [
    type,
    expression,
    periodType,
    durationType,
    duration,
    temporalAdjusterKey,
    timespanComparisons,
  ] = timespanId.split(':');
  const temporalAdjusters = rebuildTemporalAdjusters(temporalAdjusterKey);
  const comparisons = rebuildComparisons(timespanComparisons);
  const timespanSchema = {
    id: timespanId,
    type: type as TimespanType,
    temporalAdjusters,
    expression,
    periodType: periodType as PeriodType,
    durationType: durationType as DurationType,
    duration: parseInt(duration),
    ...(comparisons && { comparisons }),
  };

  let name = timespanId;
  const label = domainToTimespanLabelFn(domain)?.(timespanSchema);
  if (label) {
    name = appendPopComparisonToLabel(label, comparisons);
  }

  return {
    name,
    ...timespanSchema,
    domains: [domain],
  };
};

const organizationTimespanLabelFn = ({
  duration,
  periodType,
  temporalAdjusters,
}: TimespanSchema & { id: string }): string => {
  const minusTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('minus'),
  );

  if (minusTimeGrainAdjusters.length === 0) {
    const currentPeriodTypeToLabel: { [key in PeriodType]?: string } = {
      [PeriodType.Weekly]: 'Current WTD',
      [PeriodType.Monthly]: 'Current MTD',
      [PeriodType.Yearly]: 'Current YTD',
      [PeriodType.Quarterly]: 'Current QTD',
      [PeriodType.Pm]: 'Current Performance MTD',
      [PeriodType.Py]: 'Current Performance YTD',
      [PeriodType.Pq]: 'Current Performance QTD',
    };
    return currentPeriodTypeToLabel[periodType] || `Current ${periodType}`;
  }

  const anchorAdjusters = minusTimeGrainAdjusters.filter(
    (timespanAdjuster) =>
      timespanAdjuster.function === TemporalAdjusterFunction.minusYears,
  );
  let anchorText = '';
  if (
    anchorAdjusters.length &&
    periodType !== PeriodType.Py &&
    periodType !== PeriodType.Yearly
  ) {
    // only have two options in the map because these are the only anchors supported in org domain
    const currentPeriodTypeToLabel: { [minusYearUnits: string]: TimeAnchor } = {
      1: TimeAnchor.Year,
      2: TimeAnchor.TwoYears,
    };
    const minusYearUnits = anchorAdjusters[0].arguments[0];
    anchorText += ' ';
    anchorText +=
      currentPeriodTypeToLabel[minusYearUnits] ||
      `as of ${minusYearUnits} years prior`;
  }

  const periodTypeToTimeUnitLabel: { [key in PeriodType]?: string } = {
    [PeriodType.Weekly]: 'Weeks',
    [PeriodType.Monthly]: 'Months',
    [PeriodType.Quarterly]: 'Quarters',
    [PeriodType.Yearly]: 'Years',
    [PeriodType.Pm]: 'Performance Months',
    [PeriodType.Pq]: 'Performance Quarters',
    [PeriodType.Py]: 'Performance Years',
    [PeriodType.Ttm]: 'TTM',
    [PeriodType.T3m]: 'T3M',
    [PeriodType.T6m]: 'T6M',
  };

  const timeUnitText = periodTypeToTimeUnitLabel[periodType] || periodType;

  return `Last ${duration} ${timeUnitText}${anchorText}`;
};

const organizationRowTimespanLabelFn = ({
  id,
  duration,
  temporalAdjusters,
}: TimespanSchema & { id: string }): string => {
  const startTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('startOf'),
  );
  const minusTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('minus'),
  );

  if (
    minusTimeGrainAdjusters.length === 0 &&
    startTimeGrainAdjusters.length > 0
  ) {
    const startAdjusterFnToLabel: {
      [key in TemporalAdjusterFunction]?: string;
    } = {
      [TemporalAdjusterFunction.startOfQuarter]: 'Current QTD',
      [TemporalAdjusterFunction.startOfYear]: 'Current YTD',
      [TemporalAdjusterFunction.startOfPerfYear]: 'Current Performance YTD',
      [TemporalAdjusterFunction.startOfMonth]: 'Current Performance MTD',
    };
    const startTimeGrainAdjusterFn = startTimeGrainAdjusters[0].function;
    return (
      startAdjusterFnToLabel[startTimeGrainAdjusterFn] ||
      `Current ${startTimeGrainAdjusterFn.substring('startOf'.length)}`
    );
  }

  const anchorAdjusters = minusTimeGrainAdjusters.filter(
    (timespanAdjuster) =>
      timespanAdjuster.function === TemporalAdjusterFunction.minusYears,
  );
  let timeUnit: TimeUnit = TimeUnit.Months;
  if (
    anchorAdjusters.length === 1 &&
    startTimeGrainAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function === TemporalAdjusterFunction.startOfPerfYear,
    )
  ) {
    timeUnit = TimeUnit.PerformanceYears;
  } else if (
    anchorAdjusters.length === 1 &&
    startTimeGrainAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function === TemporalAdjusterFunction.startOfYear,
    )
  ) {
    timeUnit = TimeUnit.Years;
  } else if (
    startTimeGrainAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function === TemporalAdjusterFunction.startOfQuarter,
    )
  ) {
    timeUnit = TimeUnit.Quarters;
  }
  let anchorText = '';
  if (
    anchorAdjusters.length &&
    TimeUnit.Years !== timeUnit &&
    TimeUnit.PerformanceYears !== timeUnit
  ) {
    // only have two options in the map because these are the only anchors supported in org domain
    const currentPeriodTypeToLabel: { [minusYearUnits: string]: TimeAnchor } = {
      1: TimeAnchor.Year,
      2: TimeAnchor.TwoYears,
    };
    const minusYearUnits = anchorAdjusters[0].arguments[0];
    anchorText += ' ';
    anchorText +=
      currentPeriodTypeToLabel[minusYearUnits] ||
      `as of ${minusYearUnits} years prior`;
  }

  const startAdjusterFnToTuple: {
    [key in TimeUnit]?: { durationDivisor: number; timeUnitLabel: string };
  } = {
    [TimeUnit.Months]: { durationDivisor: 1, timeUnitLabel: 'Months' },
    [TimeUnit.Quarters]: { durationDivisor: 3, timeUnitLabel: 'Quarters' },
    [TimeUnit.Years]: { durationDivisor: 12, timeUnitLabel: 'Years' },
    [TimeUnit.PerformanceYears]: {
      durationDivisor: 12,
      timeUnitLabel: 'Performance Years',
    },
  };

  const tuple = startAdjusterFnToTuple[timeUnit];

  if (!tuple) {
    return id;
  }

  const { durationDivisor, timeUnitLabel } = tuple;
  const convertedDisplayDuration = duration / durationDivisor;

  return `Last ${convertedDisplayDuration} ${timeUnitLabel}${anchorText}`;
};

const locationTimespanLabelFn = ({
  duration,
  periodType,
  temporalAdjusters,
  type,
}: TimespanSchema & { id: string }): string => {
  if (type === TimespanType.Current_Period) {
    const periodTypeToLabel: { [key in PeriodType]?: string } = {
      [PeriodType.Intraday]: 'Current Hour',
      [PeriodType.Daily]: 'Current Day',
      [PeriodType.Weekly]: 'Current WTD',
      [PeriodType.Monthly]: 'Current MTD',
      [PeriodType.Quarterly]: 'Current QTD',
      [PeriodType.Yearly]: 'Current YTD',
    };
    return periodTypeToLabel[periodType] || `Current ${periodType}`;
  }
  const plusTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('plus'),
  );

  if (plusTimeGrainAdjusters.length > 0) {
    const periodTypeToLabel: { [key in PeriodType]?: string } = {
      [PeriodType.Intraday]: 'Hours',
      [PeriodType.Daily]: 'Days',
      [PeriodType.Weekly]: 'Weeks',
      [PeriodType.Monthly]: 'Months',
      [PeriodType.Quarterly]: 'Quarters',
      [PeriodType.Yearly]: 'Years',
    };
    return `Next ${duration} ${periodTypeToLabel[periodType] || periodType}`;
  }

  const startTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('startOf'),
  );
  const minusTimeGrainAdjusters = temporalAdjusters.filter((timespanAdjuster) =>
    timespanAdjuster.function.startsWith('minus'),
  );

  const anchorAdjusters = minusTimeGrainAdjusters.filter(
    (timespanAdjuster) =>
      timespanAdjuster.function === TemporalAdjusterFunction.minusYears,
  );
  let anchorText = '';
  if (anchorAdjusters.length && periodType !== PeriodType.Yearly) {
    // only option available in location domain
    const currentPeriodTypeToLabel: { [minusYearUnits: string]: TimeAnchor } = {
      1: TimeAnchor.Year,
    };
    const minusYearUnits = anchorAdjusters[0].arguments[0];
    anchorText += ' ';
    anchorText +=
      currentPeriodTypeToLabel[minusYearUnits] ||
      `as of ${minusYearUnits} years prior`;
  }

  if (
    PeriodType.Daily === periodType &&
    !temporalAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function === TemporalAdjusterFunction.minusWeeks,
    ) &&
    startTimeGrainAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function ===
        TemporalAdjusterFunction.startOfSundayWeek,
    ) &&
    duration === 7
  ) {
    return 'Current Calendar Week';
  }
  if (
    PeriodType.Daily === periodType &&
    startTimeGrainAdjusters.some(
      (timespanAdjuster) =>
        timespanAdjuster.function ===
        TemporalAdjusterFunction.startOfSundayWeek,
    )
  ) {
    const numOfWeeks = duration / 7;
    return `Last ${numOfWeeks} Calendar Weeks${anchorText}`;
  }

  const periodTypeToTimeUnitLabel: { [key in PeriodType]?: string } = {
    [PeriodType.Intraday]: 'Hours',
    [PeriodType.Daily]: 'Days',
    [PeriodType.Weekly]: 'Weeks',
    [PeriodType.Monthly]: 'Months',
    [PeriodType.Quarterly]: 'Quarters',
    [PeriodType.Yearly]: 'Years',
  };

  const timeUnitText = periodTypeToTimeUnitLabel[periodType] || periodType;

  return `Last ${duration} ${timeUnitText}${anchorText}`;
};

/**
 * The labeling logic for these functions are based on heuristics on the shape of the timespan configurations
 * They've only been validated against timespan configuration shapes that come from the UI, i.e rollingDatesSelectionConfigs in constants.tsx
 *
 * Because these configurations are functional in nature and can take on infinite shapes, not all conditions will be recognized.
 *
 * Therefore, if someone were to modify the configuration shape so that it doesn't look like one done through the UI's control plane,
 * then the human readable label might be off -- or flat out wrong.
 *
 * This labeling logic probably makes more sense to be thrown into some ML model to ensure some confidence that it generates some human label
 * that matches the output of the configurations. However that's overkill because we know that the shapes of these configurations
 * are theoretically bounded by the UI timespan edit modal
 */
const domainToTimespanLabelFn = (
  domain: string,
): ((param: TimespanSchema & { id: string }) => string) => {
  const existingDomains: {
    [key: string]: (param: TimespanSchema & { id: string }) => string;
  } = {
    [Domain.OrganizationRow]: organizationRowTimespanLabelFn,
    [Domain.Organization]: organizationTimespanLabelFn,
    connections: organizationTimespanLabelFn,
    talent_acquisition: organizationTimespanLabelFn,
    [Domain.Location]: locationTimespanLabelFn,
    location_row: locationTimespanLabelFn,
    [Domain.Rto]: locationTimespanLabelFn,
    [Domain.RtoRow]: locationTimespanLabelFn,
    [Domain.PiBusinessReview]: organizationTimespanLabelFn,
    people_engine_requisition: locationTimespanLabelFn,
    people_engine_requisition_row: locationTimespanLabelFn,
    people_engine_recruiting: locationTimespanLabelFn,
    people_engine_recruiting_row: locationTimespanLabelFn,
    people_engine_interview: locationTimespanLabelFn,
    people_engine_interview_row: locationTimespanLabelFn,
    engage: locationTimespanLabelFn,
    everybody: organizationTimespanLabelFn,
    everybody_row: organizationRowTimespanLabelFn,
  };
  if (domain in existingDomains) {
    return existingDomains[domain];
  }
  // default for all domains (including CMM)
  return locationTimespanLabelFn;
};
