import {
  DatasourceInfo,
  TimespanPair,
  ValueType,
} from '@amzn/claritygqllambda';
import { PivotTableHeader } from '@amzn/claritygqllambda/dist/packages/report-data/report-types';
import {
  PARTIAL_SUBTOTAL_REGEX,
  TIMESPAN_PREFIX_PERIOD_TYPE_MAP,
} from '@clarity-website/react-table-data-handler/common/constants';
import {
  EnrichedTimespanPair,
  HeadersWithMetadata,
  ProcessedData,
  ProcessedRow,
  ProcessedRowWithMetadata,
  ReactTableColumn,
  StringIndexed,
  titleSortMap,
} from '@clarity-website/react-table-data-handler/common/types';
import CommonTableMethods from '@clarity-website/reports/CommonTableMethods';
import { buildTimespanFromId } from '@clarity-website/reports/edit/timespan/commonUtils';
import { POP_METADATA_MAP_BY_PERIOD_TYPE } from '@clarity-website/reports/edit/timespan/constants';
import {
  TimespanSchema,
  extractIdFromTimespan,
} from '@clarity-website/reports/filters/timespan-config';
import { GoalColumnMetadata } from '@clarity-website/reports/goals/goalUtils';
import { BLANKS } from '@clarity-website/reports/table/ColumnActionsHelpers';
import {
  ALL,
  COMPARATOR_ROW_SUFFIX,
} from '@clarity-website/reports/table/tableSorting';
import {
  ReportTemplate,
  UnprocessedRawRow,
} from '@clarity-website/reports/useFetchRawReport';
import { isEqual } from 'lodash';

const ctm = CommonTableMethods();

export const extractValues = (data: ProcessedRow) => {
  return Object.values(data).map((next: { value: string }) => {
    return next.value;
  });
};

export const getAbsoluteTimespanPairs = (timespanTuples: TimespanPair[]) => {
  return timespanTuples.reduce(
    (acc: { [key: string]: number[] }, next, idx) => {
      if (Object.hasOwn(acc, next.absoluteTimespan)) {
        acc[next.absoluteTimespan] = [...acc[next.absoluteTimespan], idx];
      } else {
        acc[next.absoluteTimespan] = [idx];
      }
      return acc;
    },
    {},
  );
};

export const getTimespanNamesFromConfig = (
  timespanConfigs: TimespanSchema[],
  domain: string,
): string[] => {
  return timespanConfigs.map((timespan) => {
    const id = extractIdFromTimespan(timespan);
    return buildTimespanFromId(id, domain).name;
  });
};

export const buildProcessedDataForRow = (
  row: UnprocessedRawRow,
  metadata: string[],
): ProcessedRow => {
  return metadata.reduce((acc: StringIndexed, next) => {
    acc[next] = row[next];
    return acc;
  }, {});
};

export const isSubtotalRowMapper = (
  attributes: string[],
  row: ProcessedRow,
  primaryLeaderAttrId?: string,
) => {
  if (primaryLeaderAttrId) {
    return isSubtotalRowForLeaderDomain(row, primaryLeaderAttrId);
  }
  return isSubtotalRowDefault(attributes, row);
};

export const isSubtotalRowDefault = (
  attributes: string[],
  row: ProcessedRow,
) => {
  return (
    attributes.length > 1 &&
    attributes.slice(1).every((attribute) => row[attribute]?.value === '')
  );
};

export const isSubtotalRowForLeaderDomain = (
  row: ProcessedRow,
  primaryLeaderAttrId: string,
) => {
  //determine whether a row is a subtotal by checking the value of the primary leader field
  return PARTIAL_SUBTOTAL_REGEX.test(row[primaryLeaderAttrId]?.value || '');
};

export const createIdToTitleMap = (
  data: { id?: string; title?: string; sortKey?: string }[],
  goalColumns?: GoalColumnMetadata[],
) => {
  const idToTitleMap = data?.reduce(
    (acc: { [key: string]: titleSortMap }, next) => {
      if (next.id) {
        const title = next.title || '';
        return {
          ...acc,
          [next.id]: { title, sortKey: next.sortKey || title },
        };
      }
      return acc;
    },
    {},
  );
  return updateSortKeyForGoalColumns(idToTitleMap, goalColumns);
};

export const createIdToDescMap = (
  data: { id?: string; description?: string; name?: string }[],
  idToTitleMap: { [key: string]: titleSortMap },
  goalColumns?: GoalColumnMetadata[],
) => {
  const idToDescMap = data?.reduce((acc: { [key: string]: string }, next) => {
    if (next.id) {
      return {
        ...acc,
        [next.id]:
          (next.name &&
            next.description &&
            `${next.name} - ${next.description}`) ||
          '',
      };
    }
    return acc;
  }, {});

  return addGoalColumnsToIdDescriptionMap(
    idToDescMap,
    idToTitleMap,
    goalColumns,
  );
};

export const updateSortKeyForGoalColumns = (
  idToTitleMap: { [key: string]: titleSortMap },
  goalColumns?: GoalColumnMetadata[],
) => {
  goalColumns?.forEach((column) => {
    const { metricId, id, sortKey } = column;
    const colSortKey = `${idToTitleMap[metricId].title} - ${sortKey}`;
    idToTitleMap = {
      ...idToTitleMap,
      [id]: { ...idToTitleMap[id], sortKey: colSortKey },
    };
  });
  return idToTitleMap;
};

export const addGoalColumnsToIdDescriptionMap = (
  idToDescMap: { [key: string]: string },
  idToTitleMap: { [key: string]: titleSortMap },
  goalColumns?: GoalColumnMetadata[],
) => {
  goalColumns?.forEach((col) => {
    const { id } = col;
    if (id && !idToDescMap[id]) {
      idToDescMap = {
        ...idToDescMap,
        [id]: idToTitleMap[id].title,
      };
    }
  });
  return idToDescMap;
};

const recursivelyGetAccessorValues = (
  column: ReactTableColumn,
  row: ProcessedRowWithMetadata,
): { value: string; valueType?: ValueType }[] => {
  const values = [];
  if (column.columns) {
    for (const col of column.columns) {
      values.push(...recursivelyGetAccessorValues(col, row));
    }
  }
  if (column.accessorFn) {
    const { value, valueType } = column.accessorFn(row);
    values.push({ value, valueType });
  }
  return values;
};

export const convertDataToArrays = (
  data: ProcessedData,
  structure: ReactTableColumn[],
) => {
  const arrData = [];
  for (const row of data) {
    const rowData = [];
    for (const column of structure) {
      rowData.push(...recursivelyGetAccessorValues(column, row));
    }
    arrData.push(rowData);
  }
  return arrData;
};

// strings requiring special handling
function checkSpecialCases(string: string) {
  return (
    string.toLowerCase() === ALL.toLowerCase() ||
    string.toLowerCase().endsWith(COMPARATOR_ROW_SUFFIX.toLowerCase()) ||
    PARTIAL_SUBTOTAL_REGEX.test(string)
  );
}

// this is a short-term fix for XBR P0 release, the long-term fix is tracked at:
// https://sim.amazon.com/issues/P91853630
export const rowSortForExport = (
  row1: { value: string; valueType?: ValueType }[],
  row2: { value: string; valueType?: ValueType }[],
  columnsCount?: number,
  startColumnToSortBy = 0,
): number => {
  // validate both column exists
  const minColumns = Math.min(row1.length, row2.length);
  if (
    minColumns - 1 < startColumnToSortBy ||
    (columnsCount && columnsCount - 1 < startColumnToSortBy)
  ) {
    return 0;
  }

  // Extract the blobs and values that will be used to compare the rows
  const row1Blob = row1[startColumnToSortBy];
  const row2Blob = row2[startColumnToSortBy];

  const { value: row1Value } = row1Blob;
  const { value: row2Value } = row2Blob;

  // Handle special cases
  const isRow1Special = checkSpecialCases(row1Value);
  const isRow2Special = checkSpecialCases(row2Value);

  // If the blob from the rows are the same
  // (Either exactly or semantically for sorting)
  // Compare the next column between the two rows
  if (isEqual(row1Blob, row2Blob) || (isRow1Special && isRow2Special)) {
    return rowSortForExport(row1, row2, columnsCount, startColumnToSortBy + 1);
  }

  if (isRow1Special) {
    return -1;
  } else if (isRow2Special) {
    return 1;
  }

  // Try sorting the rows as numbers
  const number1 = Number(row1Value);
  const number2 = Number(row2Value);
  if (!isNaN(number1) && !isNaN(number2)) {
    return number1 - number2;
  }

  // Fall back to string sorting
  return row1Value.localeCompare(row2Value);
};

const recursivelyGetColumnDepth = (column: ReactTableColumn, depth = 1) => {
  let maxDepth = depth;
  if (column.columns) {
    maxDepth++;
    for (const col of column.columns) {
      maxDepth = Math.max(maxDepth, recursivelyGetColumnDepth(col, maxDepth));
    }
  }
  return maxDepth;
};

export const getMaxNestedColumns = (structure: ReactTableColumn[]) => {
  let currCols = 1;
  for (const column of structure) {
    if (column.columns) {
      currCols = Math.max(currCols, recursivelyGetColumnDepth(column));
    }
  }
  return currCols;
};

const recursivelyGetNumberOfColumns = (
  column: ReactTableColumn,
  runningCols = 0,
) => {
  let currCols = runningCols;
  if (column.columns) {
    for (const col of column.columns) {
      if (col.columns) {
        currCols += recursivelyGetNumberOfColumns(col, currCols + 1);
      } else {
        currCols += 1;
      }
    }
  } else {
    currCols += 1;
  }
  return currCols;
};

const getNumberOfColumns = (structure: ReactTableColumn[]) => {
  let colCount = 0;
  for (const column of structure) {
    if (column.columns) {
      colCount += recursivelyGetNumberOfColumns(column);
    } else {
      colCount += 1;
    }
  }
  return colCount;
};

const recursivelyExtractHeaders = (
  column: ReactTableColumn,
): HeadersWithMetadata[] => {
  const values = [];
  if ('header' in column) {
    values.push({
      header: column.header,
      columnDepth: column.columns ? getMaxNestedColumns(column.columns) : 0,
      columnCount: column.columns ? getNumberOfColumns(column.columns) : 0,
    });
  }
  if (column.columns) {
    for (const col of column.columns) {
      values.push(...recursivelyExtractHeaders(col));
    }
  }
  return values;
};

const getHeadersWithMetadata = (structure: ReactTableColumn[]) => {
  const arrData = [];
  for (const column of structure) {
    arrData.push(...recursivelyExtractHeaders(column));
  }
  return arrData;
};

/**
 * @returns: array of processed rows that represent the headers
 * sample input:
 * [
     { header: 'Primary leader login', columnDepth: 0, columnCount: 0 },
     { header: 'Timespans', columnDepth: 0, columnCount: 0 },
     { header: 'Job Level Group', columnDepth: 0, columnCount: 0 },
     { header: 'Average Headcount', columnDepth: 0, columnCount: 0 },
     { header: 'Male Headcount', columnDepth: 0, columnCount: 0 }
  ]
 * sample return value:
 [
   [
     'Primary leader login',
     'Timespans',
     'Job Level Group',
     'Average Headcount',
     'Male Headcount',
   ],
 ]
 */
const formatHeadersForExport = (headersWithMetadata: HeadersWithMetadata[]) => {
  const numberOfCols = Math.max(
    ...headersWithMetadata.map((header) => header.columnDepth),
  );
  const formattedHeaders: string[][] = [];
  for (let i = 0; i <= numberOfCols; i++) {
    let emptyBufferFlag = i > 0; // used to populate empty entries
    formattedHeaders[i] = [];
    for (const entry of headersWithMetadata) {
      if (emptyBufferFlag) {
        if (entry.columnDepth === 0) {
          formattedHeaders[i].push('');
        } else {
          emptyBufferFlag = false;
        }
      }
      if (entry.columnDepth === i) {
        formattedHeaders[i].push(entry.header);
        if (entry.columnCount > 0) {
          for (let j = 0; j < entry.columnCount - 1; j++) {
            formattedHeaders[i].push('');
          }
        }
      }
    }
  }
  return formattedHeaders;
};

export const getHeaderRowsForExport = (
  structure: ReactTableColumn[],
): string[][] => {
  const headersWithMetadata = getHeadersWithMetadata(structure);
  return formatHeadersForExport(headersWithMetadata).reverse();
};

export const serializeMetadataForRow = (
  row: UnprocessedRawRow,
  metadata: string[],
) => {
  return metadata.map((m) => row[m].value).join('#');
};

export const formatCellValueWrapper = (
  value: string,
  valueType?: ValueType,
) => {
  return ctm.formatCellValue(value, valueType || ValueType.String) || BLANKS;
};

export const getPeriodTypeFromRawTimespan = (timespan: string) => {
  const prefix = timespan.slice(0, timespan.indexOf('_'));
  return TIMESPAN_PREFIX_PERIOD_TYPE_MAP[prefix];
};

export const formatDateTimeLabelWrapper = (
  utcOffset: number,
  timespan: string,
  timespanName?: string,
  datasourceInfoList?: DatasourceInfo[],
  domain?: string,
) => {
  return ctm.formatDateTimeLabel(
    utcOffset,
    getPeriodTypeFromRawTimespan(timespan)!,
    timespan,
    timespanName,
    datasourceInfoList,
    domain,
  );
};

const getTimespanIndexCountMap = (tuples: TimespanPair[]) => {
  const timespanIndexCount: { [key: number]: number } = {};
  let currentIndex = null;
  for (const tuple of tuples) {
    if (currentIndex === tuple.timespanIndex) {
      timespanIndexCount[currentIndex] = timespanIndexCount[currentIndex] + 1;
    } else {
      currentIndex = tuple.timespanIndex;
      timespanIndexCount[tuple.timespanIndex] = 0;
    }
  }
  return timespanIndexCount;
};

export const enrichTimespanTuples = (
  tuples: TimespanPair[],
): EnrichedTimespanPair[] => {
  const timespanIndexCountMap = getTimespanIndexCountMap(tuples);
  return tuples.map((tuple) => {
    const count = timespanIndexCountMap[tuple.timespanIndex];
    const enrichedTuple = { ...tuple, popTimespanReverseIndex: count };
    timespanIndexCountMap[tuple.timespanIndex] = count - 1;
    return enrichedTuple;
  });
};

export const extractTimespanComparisons = (
  timespans: TimespanSchema[],
): string[] => {
  const comparisonsSet = new Set<string>();
  for (const timespan of timespans) {
    if (timespan.comparisons) {
      timespan.comparisons.forEach((tComp) =>
        comparisonsSet.add(tComp.comparison.toString()),
      );
    }
  }
  return Array.from(comparisonsSet.values());
};

export const injectPopMetrics = (
  metrics: string[],
  timespans: TimespanSchema[],
) => {
  const timespanComparisons = extractTimespanComparisons(timespans);
  return metrics.reduce((acc: string[], next) => {
    acc.push(next);
    for (const comparison of timespanComparisons) {
      acc.push(`${next}${POP_METADATA_MAP_BY_PERIOD_TYPE[comparison].postFix}`);
    }
    return acc;
  }, []);
};

export const nanValueToZero = (value: string) => {
  return value === Number.NaN.toString() ? '0' : value;
};

export const getEmptyCell = () => ({
  value: '',
  valueType: ValueType.String,
  toString: () => '',
});

export type SparkchartDataType = (string | number | JSX.Element | null)[];

export function isSparkChartNumberData(
  data: SparkchartDataType,
): data is number[] {
  return data.every((value) => typeof value === 'number');
}

export function getSparkchartData(
  tableHeader: PivotTableHeader,
  row: ProcessedRowWithMetadata,
  timespanTuples: TimespanPair[],
  reportTemplate: ReportTemplate,
) {
  const accessorTimespanIndex = tableHeader.accessFnId?.split('#')[1];
  const timespanValues = timespanTuples
    .filter(
      ({ timespanIndex }) => timespanIndex.toString() === accessorTimespanIndex,
    )
    .map(({ absoluteTimespan }) => row.processedRow[absoluteTimespan] || {});

  const sparkchartData = timespanValues.map(({ value, valueType }) => {
    if (valueType && ctm.isValueConvertibleToNumber(value, valueType)) {
      return ctm.parseValueFromValueType(value, valueType);
    }
    return ctm.getDisplayComponent(value, valueType);
  });

  const label = `sparkline describes data to the ${
    reportTemplate.sparkchartAlignment === 'right' ? 'left' : 'right'
  }`;

  return {
    value: sparkchartData,
    label,
  };
}
