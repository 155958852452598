import {
  DisplayStyleType,
  Domain,
  OperatorType,
  PeriodType,
  ReportGroupType,
  ReportType,
} from '@amzn/claritygqllambda';
import { ReportTemplateInput } from '@amzn/claritygqllambda/dist/generated/graphql';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { record } from '@clarity-website/common/Analytics';
import {
  DomainConfig,
  DomainConfigs,
  FilteredAttributesConfig,
  RowLevelReportConfig,
} from '@clarity-website/config/domain-configs';
import Message from '@clarity-website/message-banners/Message';
import {
  DEFAULT_SUCCESS_AUTO_DISMISS_AFTER_SEC,
  DEFAULT_WARNING_AUTO_DISMISS_AFTER_SEC,
  MessageGroupIds,
} from '@clarity-website/message-banners/MessageGroupContainer';
import {
  ActorType,
  Principal,
} from '@clarity-website/pages/search-reports/queries/user-permissions-queries';
import { DEFAULT_NEW_TAB_ID } from '@clarity-website/reports/ReportPage/constants';
import { UseGetReportTemplateOutput } from '@clarity-website/reports/ReportPage/useGetReportTemplate';
import { rollingDatesSelectionConfigs } from '@clarity-website/reports/edit/timespan/constants';
import {
  AdjusterFunctionConfiguration,
  PeriodTypeUtilityMapping,
} from '@clarity-website/reports/edit/timespan/periodTypeUtils';
import {
  PeriodAnchor,
  TimePresence,
  TimeUnit,
} from '@clarity-website/reports/edit/timespan/types';
import { TemplateSectionItems } from '@clarity-website/reports/filters/TemplateSectionItems';
import { FilterDictionaryType } from '@clarity-website/reports/filters/constructFilterConfig';
import {
  AttributeInterface,
  LEADER_HIERARCHY_FILTER_ID,
  MetricsInterface,
  REPORT_FILTER_SEPARATOR,
} from '@clarity-website/reports/filters/filter-types';
import {
  extractAttributesFromReportTemplate,
  generateFilterIdStr,
  translateFiltersToFiltersV2,
} from '@clarity-website/reports/filters/filter-utils';
import { FilterState } from '@clarity-website/reports/filters/filterReducerTypes';
import {
  DurationType,
  TemporalAdjusterFunction,
  TimespanSchema,
  TimespanType,
} from '@clarity-website/reports/filters/timespan-config';
import { hasMetricGoalTogglesUpdated } from '@clarity-website/reports/goals/goalUtils';
import { SAVE_TYPE } from '@clarity-website/reports/mutation-hooks/types';
import {
  ReportFilter,
  ReportFilterRule,
  ReportFilterV2,
  ReportGroupInput,
  ReportGroupOutput,
  ReportGroupSheet,
  ReportTemplateInterface,
} from '@clarity-website/reports/report-types';
import {
  ReportTemplate,
  ReportTemplateWithoutId,
} from '@clarity-website/reports/useFetchRawReport';
import { truthyFilter } from '@clarity-website/utils/type-utils';
import { intersection, pluck } from 'ramda';

const GREF_TAG = 'GREF';
const SINGLE_TIMESPAN_SELECTION_DOMAIN = ['location_row'];

export const isTimespanMultiSelectEnabled = (domain: string) => {
  return !SINGLE_TIMESPAN_SELECTION_DOMAIN.includes(domain);
};

export function removeTypename<T>(obj: T & { __typename?: string }) {
  const { __typename: _omit, ...rest } = obj;
  return { ...rest };
}

export function removeTypenameFromTemplate(reportTemplate: any) {
  const {
    __typename: _omit,
    selectedTimespans,
    filters,
    ...rest
  } = reportTemplate;
  const cleanTimespans = selectedTimespans?.map(removeTypename);
  const cleanFilters = filters?.map(removeTypename);
  return {
    ...rest,
    selectedTimespans: cleanTimespans,
    filters: cleanFilters,
  };
}

export function hasSubtotalsModified(
  subtotals:
    | {
        pristine: boolean;
        applied: boolean;
        current: boolean;
      }
    | undefined,
) {
  return !!subtotals && subtotals.current !== subtotals.pristine;
}

function hasTemplateItemsModified(
  map1: TemplateSectionItems,
  map2: TemplateSectionItems,
) {
  return (
    map1.items.size !== map2.items.size ||
    map1.keys.some((key, index) => {
      return (
        map2.keys[index] !== key ||
        JSON.stringify(map1.items.get(key)) !==
          JSON.stringify(map2.items.get(key))
      );
    })
  );
}

export function filterStateIsPristine(modalState: FilterState): boolean {
  return Object.values(modalState).every((state) => {
    const {
      pristineAppliedFilters,
      appliedFilters,
      pristineSelectedGoals,
      appliedSelectedGoalsMetricMap,
      setGoalsPayloadMap,
      deleteGoalsPayloadMap,
      subtotals,
    } = state;
    return (
      !hasTemplateItemsModified(pristineAppliedFilters, appliedFilters) &&
      setGoalsPayloadMap.size === 0 &&
      deleteGoalsPayloadMap.size === 0 &&
      !hasMetricGoalTogglesUpdated(
        appliedSelectedGoalsMetricMap,
        pristineSelectedGoals,
      ) &&
      !hasSubtotalsModified(subtotals)
    );
  });
}

export function extractAppliedFilters(filterState: FilterState): string[] {
  return Object.values(filterState).flatMap(
    (state) => state.appliedFilters?.keys || [],
  );
}

export function createCustomTemplate(
  reportTemplate: any,
  filterDictionary: FilterDictionaryType,
  modalState: FilterState,
  domainConfigs?: DomainConfigs,
  name?: string,
  saveType?: SAVE_TYPE,
) {
  const primaryLeaderLogins = new Array<string>();
  const cleanTemplate = removeTypenameFromTemplate(reportTemplate);
  const filteredAttributesConfig =
    domainConfigs?.filteredAttributes[reportTemplate.domain];
  const isLeaderFiltersAllowedDomain =
    domainConfigs?.config[reportTemplate.domain]?.isLeaderFiltersAllowedDomain;
  const isRowLevelDomain =
    domainConfigs?.config[reportTemplate.domain]?.isRowLevelDomain;
  const selectedTimespans = [
    ...modalState.timespan.appliedFilters.items.entries(),
  ]
    .map(([timespanKey, timespanAdditionalProps]) => {
      const itemDetails = filterDictionary.get(timespanKey) || ({} as any);
      return {
        duration: itemDetails.duration,
        durationType: itemDetails.durationType,
        expression: itemDetails.expression,
        periodType: itemDetails.periodType,
        type: itemDetails.type,
        temporalAdjusters: itemDetails.temporalAdjusters,
        comparisons: itemDetails.comparisons,
        isSparkchartActive: timespanAdditionalProps.isSparkchartActive,
      };
    })
    .filter(Boolean);

  interface FilterInterface {
    attributeId: string;
    operator: string;
    values: string[];
  }

  let selectedGroupingAttributeIds = modalState.attributes.appliedFilters.keys
    .map((filter: string) => {
      const itemDetails = filterDictionary.get(filter) || ({} as any);
      return itemDetails.id;
    })
    .filter(Boolean);

  if (
    isLeaderFiltersAllowedDomain &&
    filteredAttributesConfig &&
    filteredAttributesConfig.primaryLeaderInDirectsAttrId
  ) {
    selectedGroupingAttributeIds = orgDomainAttributeModifications(
      selectedGroupingAttributeIds,
      filteredAttributesConfig,
    );
  }

  const transformedFilters = modalState.filters.appliedFilters.keys.flatMap(
    (filterStr) => {
      const [filterValue, attributeId, included] = filterStr.split(
        REPORT_FILTER_SEPARATOR,
      );
      if (attributeId === LEADER_HIERARCHY_FILTER_ID) return []; // The hierarchy filters will be excluded from the filters array in the report template.
      if (attributeId === filteredAttributesConfig?.primaryLeaderAttrId) {
        primaryLeaderLogins.push(filterValue);
      }
      if (
        attributeId === filteredAttributesConfig?.directManagerAttrId ||
        attributeId === filteredAttributesConfig?.directManagerAttrIdGQL
      ) {
        const newAttrs = filteredAttributesConfig?.simReportsToAttributeIds;
        const addedFilters = newAttrs
          ? newAttrs.map((attr) =>
              generateFilterIdStr(filterValue, attr, parseBoolean(included)),
            )
          : [];
        return [...addedFilters];
      }
      return [filterStr];
    },
  );

  const filters: FilterInterface[] = Object.values(
    transformedFilters.reduce(
      (acc: { [key: string]: FilterInterface }, filter: string) => {
        const [filterValue, attributeId, included] = filter.split(
          REPORT_FILTER_SEPARATOR,
        );
        if (filterValue && attributeId && included) {
          const operator = parseBoolean(included)
            ? OperatorType.In
            : OperatorType.NotIn;
          if (attributeId in acc) {
            const previous = acc[attributeId];
            const newValues = [...previous.values, filterValue];
            return {
              ...acc,
              [attributeId]: {
                ...previous,
                values: newValues,
              },
            };
          }
          return {
            ...acc,
            [attributeId]: {
              attributeId,
              operator,
              values: [filterValue],
            },
          };
        }
        return acc;
      },
      {},
    ),
  ).filter(Boolean);

  const groupedFilters = transformedFilters
    .map((item) => item.split(REPORT_FILTER_SEPARATOR))
    .reduce((result: Record<string, ReportFilterRule>, filterString) => {
      const [filterValue, attributeId, included] = filterString;

      const operator = parseBoolean(included)
        ? OperatorType.In
        : OperatorType.NotIn;

      const opAttrId = `${operator}-${attributeId}`;

      const rule = result[opAttrId] ?? {};
      const operatorRule = rule[operator] ?? {};

      return {
        ...result,
        [opAttrId]: {
          [operator]: {
            [attributeId]: [...(operatorRule[attributeId] ?? []), filterValue],
          },
        },
      };
    }, {});

  const initialFiltersV2 = {
    [OperatorType.And]: Object.values(groupedFilters),
  };

  const filtersV2: ReportFilterV2 =
    primaryLeaderLogins.length > 0 &&
    filteredAttributesConfig &&
    isLeaderFiltersAllowedDomain &&
    filteredAttributesConfig.primaryLeaderInDirectsAttrId
      ? orgDomainFilterModifications(
          initialFiltersV2,
          filteredAttributesConfig,
          selectedGroupingAttributeIds || [],
          primaryLeaderLogins,
        )
      : initialFiltersV2;

  if (isRowLevelDomain) {
    const metricColumns = modalState.metricColumns.appliedFilters.keys
      .map((filter: string) => {
        const itemDetails = filterDictionary.get(filter) || ({} as any);
        return itemDetails.id;
      })
      .filter(Boolean);
    selectedGroupingAttributeIds = [
      ...selectedGroupingAttributeIds,
      ...metricColumns,
    ];
  }

  const selectedMetricIds = modalState.metrics.appliedFilters.keys
    .map((filter: string) => {
      const itemDetails = filterDictionary.get(filter) || ({} as any);
      return itemDetails.id;
    })
    .filter(Boolean);

  const selectedMetricGoalMap = Array.from(
    modalState.metrics.appliedSelectedGoalsMetricMap.values(),
  );

  const setGoalsPayload = Array.from(
    modalState.metrics.setGoalsPayloadMap.values(),
  );

  const deleteGoalsPayload = Array.from(
    modalState.metrics.deleteGoalsPayloadMap,
    ([metricId, goalIds]) => ({ metricId, goalIds }),
  );

  const sparkchartAlignment =
    modalState.templateProps?.appliedFilters.items.get(
      'templateProps',
    )?.sparkchartAlignment;

  return {
    ...cleanTemplate,
    name: name || cleanTemplate.name,
    reportType: ReportType.Custom,
    // For copy mode, record the reportTemplate undergoing edit as the parentReportTemplateId for provenance purposes
    // Else modifying an existing template, preserve the existing parentReportTemplateId provenance
    parentReportTemplateId:
      saveType === SAVE_TYPE.COPY
        ? reportTemplate.id
        : reportTemplate.parentReportTemplateId || '',
    selectedTimespans,
    selectedGroupingAttributeIds,
    selectedMetricIds,
    filters,
    filtersV2: JSON.stringify(filtersV2),
    subtotals: !!modalState.filters.subtotals?.applied,
    hierarchyName: modalState.filters.hierarchyName?.applied,
    selectedGoals: selectedMetricGoalMap,
    setGoalsPayload,
    deleteGoalsPayload,
    hasReportGoals: reportTemplate.hasReportGoals || setGoalsPayload.length > 0,
    ...(sparkchartAlignment && { sparkchartAlignment }),
  };
}

export function trimReportTemplateInput(
  customTemplate: ReturnType<typeof createCustomTemplate>,
): ReportTemplateInput {
  return {
    id: customTemplate.id,
    name: customTemplate.name,
    description: customTemplate.description,
    selectedTimespans: customTemplate.selectedTimespans,
    filters: customTemplate.filters,
    filtersV2: customTemplate.filtersV2,
    selectedGroupingAttributeIds: customTemplate.selectedGroupingAttributeIds,
    selectedMetricIds: customTemplate.selectedMetricIds,
    parentReportTemplateId: customTemplate.parentReportTemplateId,
    reportType: customTemplate.reportType,
    displayStyle: customTemplate.displayStyle,
    domain: customTemplate.domain,
    subtotals: customTemplate.subtotals,
    hierarchyName: customTemplate.hierarchyName,
    hasReportGoals: customTemplate.hasReportGoals ?? false,
    selectedGoals: customTemplate.selectedGoals ?? [],
    ...(customTemplate.setGoalsPayload && {
      setGoalsPayload: customTemplate.setGoalsPayload,
    }),
    ...(customTemplate.deleteGoalsPayload && {
      deleteGoalsPayload: customTemplate.deleteGoalsPayload,
    }),
    ...(customTemplate.sparkchartAlignment && {
      sparkchartAlignment: customTemplate.sparkchartAlignment,
    }),
  };
}

export function newReportTemplateInputFromReportTemplateData(
  reportTemplateData: UseGetReportTemplateOutput,
  domainConfigs?: DomainConfigs,
) {
  return {
    ...trimReportTemplateInput(
      createCustomTemplate(
        reportTemplateData.reportQuery.reportTemplate,
        reportTemplateData.attsAndMets.filterDictionary,
        reportTemplateData.edit.state,
        domainConfigs,
      ),
    ),
  };
}

export function convertReportTemplateToReportTemplateInput(
  reportTemplate: ReportTemplate | ReportTemplateWithoutId,
) {
  return trimReportTemplateInput({
    ...reportTemplate,
    reportType: reportTemplate.reportType || ReportType.Custom,
    selectedTimespans: (reportTemplate.selectedTimespans as any) || [],
  });
}

export function newReportTemplateInputFromReportTemplate(
  reportTemplate: ReportTemplate | ReportTemplateWithoutId,
): ReportTemplateInput {
  return {
    ...convertReportTemplateToReportTemplateInput(reportTemplate),
  };
}

export function createRowLevelFromAggregateReport(
  reportTemplate: Partial<ReportTemplate>,
  rowLevelDomain: string,
  rowLevelAttrs: AttributeInterface[] = [],
  aggregateAttrs: AttributeInterface[] = [],
  aggregateMetrics: MetricsInterface[] = [],
  rowDomainConfig: DomainConfig,
  rowLevelReportConfig: RowLevelReportConfig,
  rowDomainFilteredAttributesConfig: FilteredAttributesConfig,
  aggregateDomainFilteredAttributesConfig: FilteredAttributesConfig,
) {
  const specialMapping = {
    [aggregateDomainFilteredAttributesConfig?.primaryLeaderInDirectsAttrId]:
      rowDomainFilteredAttributesConfig?.primaryLeaderInDirectsAttrId,
    [aggregateDomainFilteredAttributesConfig?.directsAttrId]:
      rowDomainFilteredAttributesConfig?.directsAttrId,
    [aggregateDomainFilteredAttributesConfig?.primaryLeaderAttrId]:
      rowDomainFilteredAttributesConfig?.primaryLeaderAttrId,
  };
  const { id, reportSubType, rootReportTemplateId, ...rest } = reportTemplate;
  const cleanTemplate = removeTypenameFromTemplate(rest);
  const selectedTimespans = reportTemplate.selectedTimespans;

  const aggregateToRowLevelAttributeMapping =
    createAttributeMappingFromAggregateToRowLevel(
      rowLevelAttrs,
      aggregateAttrs,
      specialMapping,
    );
  const metricToRowLevelAttibutesMapping = createMetricToAttributeMapping(
    aggregateMetrics,
    rowLevelAttrs,
  );
  const metricColumns = new Set(
    (reportTemplate.selectedMetricIds || []).flatMap(
      (m) => metricToRowLevelAttibutesMapping[m],
    ),
  );
  if (!metricColumns.size) {
    record({
      name: 'row-level-report-with-empty-metric-columns',
      attributes: { id },
    });
  }

  const filters = reportTemplate.filters
    ?.map((filter: { attributeId: string }) => {
      return {
        ...filter,
        attributeId: aggregateToRowLevelAttributeMapping[filter.attributeId],
      };
    })
    .filter(
      (filter: { attributeId: string }) => filter.attributeId !== undefined,
    );

  const baselineFlagFilter: ReportFilter = {
    attributeId: rowLevelReportConfig.baselineFilterAttrId!,
    operator: OperatorType.In,
    values: ['N'],
  };

  if (baselineFlagFilter.attributeId) {
    filters?.push(baselineFlagFilter);
  }

  const selectedAggregateMetrics = aggregateMetrics.filter((m) =>
    (reportTemplate.selectedMetricIds || []).includes(m.id),
  );

  return {
    ...cleanTemplate,
    domain: rowLevelDomain,
    name: `${cleanTemplate.name} - employee row level`,
    parentReportTemplateId: reportTemplate.id,
    selectedTimespans: convertTimespansForRowDomain(rowLevelDomain)
      ? convertTimespansForRowDomain(rowLevelDomain)?.(
          selectedTimespans || [],
          selectedAggregateMetrics,
          rowDomainConfig,
        )
      : selectedTimespans,
    selectedGroupingAttributeIds: getRowLevelGroupingAttributes(
      reportTemplate,
      aggregateToRowLevelAttributeMapping,
      metricColumns,
      rowDomainConfig,
      rowLevelReportConfig,
      rowDomainFilteredAttributesConfig,
    ),
    selectedMetricIds: [],
    filters,
    displayStyle: DisplayStyleType.RawData,
    filtersV2: reportTemplate.filtersV2
      ? JSON.stringify(
          appendBaselineFilter(
            baselineFlagFilter,
            convertAggregateFiltersV2ToRowLevel(
              reportTemplate.filtersV2,
              aggregateToRowLevelAttributeMapping,
            ),
          ),
        )
      : undefined,
  };
}

function getRowLevelGroupingAttributes(
  reportTemplate: Partial<ReportTemplate>,
  aggregateToRowLevelAttributeMapping: { [x: string]: string },
  metricColumns: Set<string>,
  domainConfig: DomainConfig,
  rowLevelReportConfig: RowLevelReportConfig,
  rowDomainFilteredAttributesConfig: FilteredAttributesConfig,
) {
  const attributesFromAggregateReport =
    reportTemplate.selectedGroupingAttributeIds
      ?.map((id) => aggregateToRowLevelAttributeMapping[id])
      .filter(Boolean) || [];
  const {
    primaryLeaderInDirectsAttrId = '',
    primaryLeaderAttrId = '',
    directManagerAttrId = '',
  } = rowDomainFilteredAttributesConfig || {};

  let leaderColumns;
  if (attributesFromAggregateReport.includes(primaryLeaderInDirectsAttrId)) {
    leaderColumns = [primaryLeaderInDirectsAttrId];
  } else {
    leaderColumns = [primaryLeaderAttrId, directManagerAttrId];
  }
  return [
    ...new Set(
      [
        ...(domainConfig?.requiredColumns || []),
        ...leaderColumns,
        ...(rowLevelReportConfig?.initialColumns || []),
        ...attributesFromAggregateReport,
        ...metricColumns,
      ].filter(Boolean),
    ),
  ];
}

function convertAggregateFiltersV2ToRowLevel(
  aggregateFilters: string,
  aggregateToRowLevelAttributeMapping: Record<string, string>,
) {
  Object.keys(aggregateToRowLevelAttributeMapping).forEach((key) => {
    aggregateFilters = aggregateFilters.replace(
      new RegExp(`"${key}"`, 'g'),
      `"${aggregateToRowLevelAttributeMapping[key]}"`,
    );
  });
  return JSON.parse(aggregateFilters) as ReportFilterV2;
}

function appendBaselineFilter(
  baselineFlagFilter: ReportFilter,
  reportFilterV2: ReportFilterV2,
) {
  if (!reportFilterV2) {
    return undefined;
  }
  if (!baselineFlagFilter.attributeId) {
    return reportFilterV2;
  }
  return {
    And: [
      ...(reportFilterV2?.And || []),
      ...(translateFiltersToFiltersV2([baselineFlagFilter])?.And || []),
    ],
  };
}

/**
 * Converts quarterly, yearly, ttm(s) to monthly
 * For the others, will return empty
 * @param timespanConfigs input timespan config array
 */
const convertTimespansToMonthlyTimespansFn = (
  timespanConfigs: TimespanSchema[],
  _selectedMetrics?: MetricsInterface[],
  rowDomainConfig?: DomainConfig,
): TimespanSchema[] => {
  const monthlyTimespanConfigMap = rowDomainConfig?.timespanConfigurationMap?.[
    PeriodType.Monthly
  ] || {
    periodAnchor: PeriodAnchor.Begin,
    timePresences: [] as TimePresence[],
    timeAnchor: [] as string[],
  };

  const periodAnchor = monthlyTimespanConfigMap.periodAnchor;
  const hasPartialData = monthlyTimespanConfigMap.timePresences.includes(
    TimePresence.Current,
  );

  const periodTypeUtilityMapping =
    PeriodTypeUtilityMapping[PeriodType.Monthly]!;
  const adjusterFunctionConfiguration =
    periodTypeUtilityMapping.getAdjusterFunctionConfiguration(periodAnchor);
  const defaultLast1Month = {
    duration: 1,
    durationType: DurationType.Month,
    expression: adjusterFunctionConfiguration.anchorExpression,
    periodType: PeriodType.Monthly,
    temporalAdjusters: [
      {
        arguments: [],
        function: TemporalAdjusterFunction.maxAbsTimespan,
      },
      ...adjusterFunctionConfiguration.anchorTemporalAdjusters,
      {
        arguments: hasPartialData ? ['1'] : ['0'],
        function: TemporalAdjusterFunction.minusMonths,
      },
    ],
    type: TimespanType.Cron_Sequence,
  };

  return timespanConfigs
    .map((timespanConfig) => {
      switch (timespanConfig.periodType) {
        case PeriodType.Py:
          // current perf year
          if (timespanConfig.temporalAdjusters?.length === 1) {
            return rollingDatesSelectionConfigs[Domain.OrganizationRow]?.[
              TimePresence.Current
            ]?.[TimeUnit.PerformanceYearToDate]?.timespanSchemaFn(
              timespanConfig.duration,
            );
          }
          // Last N perf year
          return rollingDatesSelectionConfigs[Domain.OrganizationRow]?.[
            TimePresence.Last
          ]?.[TimeUnit.PerformanceYears]?.timespanSchemaFn(
            timespanConfig.duration,
          );
        case PeriodType.Yearly:
          return getNonTrailingTimespan(
            timespanConfig,
            12,
            adjusterFunctionConfiguration,
            periodAnchor,
          );
        case PeriodType.Quarterly:
          return getNonTrailingTimespan(
            timespanConfig,
            3,
            adjusterFunctionConfiguration,
            periodAnchor,
          );
        case PeriodType.Pm:
          return getTimespanForMonthBasedPeriodTypes(
            timespanConfig,
            1,
            adjusterFunctionConfiguration,
            hasPartialData,
          );
        case PeriodType.Ttm:
          return getTimespanForMonthBasedPeriodTypes(
            timespanConfig,
            12,
            adjusterFunctionConfiguration,
            hasPartialData,
          );
        case PeriodType.T3m:
          return getTimespanForMonthBasedPeriodTypes(
            timespanConfig,
            3,
            adjusterFunctionConfiguration,
            hasPartialData,
          );
        case PeriodType.T6m:
          return getTimespanForMonthBasedPeriodTypes(
            timespanConfig,
            6,
            adjusterFunctionConfiguration,
            hasPartialData,
          );
        case PeriodType.Monthly:
          return getTimespanForMonthBasedPeriodTypes(
            timespanConfig,
            1,
            adjusterFunctionConfiguration,
            hasPartialData,
          );
        case PeriodType.Pq:
          return getNonTrailingTimespan(
            timespanConfig,
            3,
            adjusterFunctionConfiguration,
            periodAnchor,
          );
        default:
          return defaultLast1Month;
      }
    })
    .filter(truthyFilter);
};

/**
 * For RTO domain, the following conversion logic for rolling dates is applied
 * 1. Row level supports both Daily and Weekly granularities
 * 2. If the aggregate report has RTO GREF attendance metrics, then also expose the weekly row level record associated with
 *    the daily record
 * 3. Weekly granularity does not have Current WTD data, so we do a best efforts attempt to show the prior week (even though the mapping isnt correct)
 *    However, product is fine with this behavior
 * @param timespanConfigs input timespan config array and metrics
 */
const rtoConvertAggregateToRowLevelFn = (
  timespanConfigs: TimespanSchema[],
  selectedMetrics?: MetricsInterface[],
): TimespanSchema[] => {
  const hasGrefMetric = selectedMetrics?.some((metric) => {
    const metricTags = metric.tags || [];
    return metricTags.includes(GREF_TAG);
  });
  return timespanConfigs.flatMap((cfg) => {
    const isSpecificDateSelection = cfg.temporalAdjusters.some(
      (adjuster) => adjuster.function === TemporalAdjusterFunction.identity,
    );
    if (
      (cfg.periodType === PeriodType.Daily && !hasGrefMetric) ||
      cfg.periodType === PeriodType.Weekly ||
      isSpecificDateSelection
    ) {
      return [cfg];
    } else if (cfg.periodType === PeriodType.Daily && hasGrefMetric) {
      // for daily RTO GREF attendance metrics, include both Daily and Weekly row level records
      const newTimespanCfgs: TimespanSchema[] = [cfg];
      // RTO Weekly granularity does not have WTD data, so adding data starting from the prior week even though the mapping isn't correct
      // apply ceiling. e.g. if Last 3 days is chosen in aggregate, we should have an additional week that allows for a complete week to show
      const lastNWeekDuration = Math.ceil(cfg.duration / 7.0);
      newTimespanCfgs.push({
        duration: lastNWeekDuration,
        durationType: DurationType.Week,
        expression: '0 0 0 ? * SUN *',
        periodType: PeriodType.Weekly,
        temporalAdjusters: [
          {
            arguments: [],
            function: TemporalAdjusterFunction.maxAbsTimespan,
          },
          {
            arguments: [`${lastNWeekDuration - 1}`],
            function: TemporalAdjusterFunction.minusWeeks,
          },
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfSundayWeek,
          },
        ],
        type: TimespanType.Cron_Sequence,
      });
      return newTimespanCfgs;
    }
    throw new Error(`unsupported config ${JSON.stringify(cfg)}`);
  });
};

export const convertTimespansForRowDomain = (
  key: string,
):
  | ((
      timespanConfigs: TimespanSchema[],
      selectedMetrics?: MetricsInterface[],
      rowDomainConfig?: DomainConfig,
    ) => TimespanSchema[])
  | undefined => {
  if (key === Domain.RtoRow) {
    return rtoConvertAggregateToRowLevelFn;
  } else if (key.startsWith('people_engine') || key === 'location_row') {
    return (timespanConfigs) => timespanConfigs;
  }
  return convertTimespansToMonthlyTimespansFn;
};

function getNonTrailingTimespan(
  timespanConfig: TimespanSchema,
  numOfMonths: number,
  monthlyAdjusterFunctionConfiguration: AdjusterFunctionConfiguration,
  periodAnchor: PeriodAnchor,
): TimespanSchema {
  return {
    duration: timespanConfig.duration * numOfMonths,
    durationType: DurationType.Month,
    expression: monthlyAdjusterFunctionConfiguration.anchorExpression,
    periodType: PeriodType.Monthly,
    temporalAdjusters: timespanConfig.temporalAdjusters?.flatMap((adjuster) => {
      if (
        [PeriodType.Quarterly, PeriodType.Pq].includes(
          timespanConfig.periodType,
        ) &&
        (adjuster.function === TemporalAdjusterFunction.minusQuarters || // last n quarters/pq
          (timespanConfig.temporalAdjusters.length === 1 && // current QTD
            timespanConfig.temporalAdjusters[0].function ===
              TemporalAdjusterFunction.maxAbsTimespan))
      ) {
        return [
          adjuster,
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfQuarter,
          },
        ];
      } else if (
        timespanConfig.periodType === PeriodType.Yearly &&
        (adjuster.function === TemporalAdjusterFunction.minusYears || // last n years
          (timespanConfig.temporalAdjusters.length === 1 && // current YTD
            timespanConfig.temporalAdjusters[0].function ===
              TemporalAdjusterFunction.maxAbsTimespan))
      ) {
        return [
          adjuster,
          {
            arguments: [],
            function: TemporalAdjusterFunction.startOfYear,
          },
        ];
      }
      return [adjuster];
    }),
    type: TimespanType.Cron_Sequence,
  };
}

function getMinusMonthsDurationArgument(
  duration: number,
  hasPartialData: boolean,
) {
  return hasPartialData ? duration : duration - 1;
}
// Used for Month types PeriodType.Pm, PeriodType.Monthly, PeriodType.Ttm, PeriodType.T3m, PeriodType.T6m, because those have offsets of 1 in its duration when dealing with
// Last N MMonth
function getTimespanForMonthBasedPeriodTypes(
  { duration, periodType, temporalAdjusters }: TimespanSchema,
  numOfMonths: number,
  adjusterFunctionConfiguration: AdjusterFunctionConfiguration,
  hasPartialData: boolean,
): TimespanSchema {
  return {
    duration: duration * numOfMonths,
    durationType: DurationType.Month,
    expression: adjusterFunctionConfiguration.anchorExpression,
    periodType: PeriodType.Monthly,
    temporalAdjusters: temporalAdjusters.map((adjuster) => {
      if (adjuster.function === TemporalAdjusterFunction.minusMonths) {
        return {
          arguments: [
            getMinusMonthsDurationArgument(
              duration * numOfMonths,
              hasPartialData,
            ).toString(),
          ],
          function: TemporalAdjusterFunction.minusMonths,
        };
      }
      return adjuster;
    }),
    type: TimespanType.Cron_Sequence,
  };
}

export function createCustomReportGroup(
  reportGroup: ReportGroupOutput,
  newSheets: ReportGroupSheet[],
  name?: string,
  description?: string,
  version?: number,
  saveType?: SAVE_TYPE,
): ReportGroupInput {
  const {
    sheets,
    url,
    ownedBy,
    parentSite,
    principals,
    reportGoals,
    amazonKPIGoals,
    ...cleanReportGroup
  } = removeTypename(reportGroup);
  return {
    ...cleanReportGroup,
    name: name || cleanReportGroup.name,
    description,
    tabIds: pluck('id')(newSheets),
    // For copy mode, record the reportGroup undergoing edit as the parentReportGroupId for provenance purposes
    // Else modifying an existing reportGroup, preserve the existing parentReportGroupId provenance
    parentReportGroupId:
      saveType === SAVE_TYPE.COPY
        ? cleanReportGroup.id
        : cleanReportGroup.parentReportGroupId || '',
    reportGroupType: ReportGroupType.Custom,
    principals: principals.map((p) => removeTypename(p)),
    version: version || reportGroup.version,
  };
}

export function createCustomTemplateToSave(
  { id, reportSubType, rootReportTemplateId, ...rest }: ReportTemplateInterface,
  saveType: SAVE_TYPE,
) {
  if (saveType === SAVE_TYPE.COPY) {
    return { id, ...rest };
  }
  if (saveType === SAVE_TYPE.COPY_SHEET) {
    return { id: DEFAULT_NEW_TAB_ID, ...rest };
  }
  if (saveType === SAVE_TYPE.TRANSPOSE) {
    return {
      id,
      ...rest,
      displayStyle:
        rest.displayStyle === DisplayStyleType.MetricOnColumn
          ? DisplayStyleType.MetricOnRow
          : DisplayStyleType.MetricOnColumn,
    };
  }
  return { id, ...rest };
}

export function createCustomGroupToSave(
  group: ReportGroupInput,
  saveType: SAVE_TYPE,
) {
  const { id, ...groupWithoutId } = group;
  if (saveType === SAVE_TYPE.COPY) {
    return groupWithoutId;
  }
  return group;
}

export function getReportGroupErrorMessage(error: Error, cta = false) {
  const notFound = /not found/.test(error.message);
  if (cta && notFound) {
    return 'This report has expired. Please create it again and save it to your library.';
  }
  if (notFound) {
    return "We couldn't find this report!";
  }
  return error.message;
}

/**
 * Method that accommodates both ES metadata report group documents and reportgroup model objects to check
 * if a report is shared with multiple users.
 *
 * If there is an ANT group, return true. Otherwise, check if principals contain more than 1 member
 * @param principals
 */
export const isSharedWithMultipleUsers = (
  principals: Principal[] | string[],
) => {
  if (!principals.length) {
    return false;
  }
  if (typeof principals[0] === 'string') {
    return (
      principals.length > 1 ||
      (principals as string[]).some((p) => p.includes(`#${ActorType.ANT}#`))
    );
  }
  return (
    principals.length > 1 ||
    (principals as Principal[]).some((p) => p.type === ActorType.ANT)
  );
};

export const getReportGroupUrl = {
  [ReportGroupType.External]: (reportGroup: ReportGroupOutput) =>
    reportGroup.url || '',
  [ReportGroupType.Custom]: (reportGroup: ReportGroupOutput) => {
    return window.location.origin + `/your-reports/${reportGroup.id}`;
  },
  [ReportGroupType.Standard]: (reportGroup: ReportGroupOutput) => {
    return window.location.origin + `/browse/reportgroup/${reportGroup.id}`;
  },
  [ReportGroupType.Transient]: (reportGroup: ReportGroupOutput) => {
    return window.location.origin + `/your-reports/${reportGroup.id}`;
  },
  [ReportGroupType.Widget]: (reportGroup: ReportGroupOutput) => {
    return window.location.origin + `/browse/reportgroup/${reportGroup.id}`;
  },
  [ReportGroupType.DynamicFilter]: (reportGroup: ReportGroupOutput) => {
    return window.location.origin + `/browse/reportgroup/${reportGroup.id}`;
  },
};

export function parseBoolean(str: string) {
  if (str === 'true') {
    return true;
  }
  if (str === 'false') {
    return false;
  }
  return undefined;
}

const trimSqlColumn = (sqlColumn: string, postfix: string) => {
  // if sqlColumn has a given postfix, we will remove it
  if (sqlColumn.endsWith(postfix)) {
    sqlColumn = sqlColumn.slice(0, -postfix.length);
  }
  if (
    sqlColumn.startsWith('ttm_') ||
    sqlColumn.startsWith('t3m_') ||
    sqlColumn.startsWith('t6m_')
  ) {
    sqlColumn = sqlColumn.slice(4);
  }
  return sqlColumn;
};

export const createAttributeMappingFromAggregateToRowLevel = (
  rowLevelAttrs: AttributeInterface[] = [],
  aggregateAttrs: AttributeInterface[] = [],
  specialMapping: Record<string, string>,
) => {
  const aggregateAttrMap = aggregateAttrs.reduce(
    (map: Record<string, AttributeInterface>, attr) => {
      if (attr.sqlColumn) {
        map[attr.sqlColumn] = attr;
      }
      return map;
    },
    {},
  );

  const mapping = rowLevelAttrs.reduce((map: Record<string, string>, attr) => {
    if (attr.sqlColumn && aggregateAttrMap[attr.sqlColumn] !== undefined) {
      map[aggregateAttrMap[attr.sqlColumn].id] = attr.id;
    }
    return map;
  }, {});

  const withSpecialMapping = {
    ...mapping,
    ...specialMapping,
  };

  return withSpecialMapping;
};

export const createMetricToAttributeMapping = (
  metrics: MetricsInterface[] = [],
  rowLevelAttrs: AttributeInterface[] = [],
) => {
  const rowAttrMap = rowLevelAttrs.reduce(
    (map: Record<string, AttributeInterface>, attr) => {
      if (attr.sqlColumn) {
        const rowLevelColumn = trimSqlColumn(attr.sqlColumn, '_bool');
        map[rowLevelColumn] = attr;
        const derivedColumn = trimSqlColumn(attr.sqlColumn, '_derived');
        map[derivedColumn] = attr;
      }
      return map;
    },
    {},
  );

  return metrics.reduce((map: Record<string, string[]>, metric) => {
    if (metric.metricColumns) {
      map[metric.id] = metric.metricColumns
        .map((element) => rowAttrMap[trimSqlColumn(element, '_cum')]?.id)
        .filter(Boolean);
    }
    return map;
  }, {});
};

export const insertNewReportGroupTabNextToCurrentTab = (
  tabs: ReportGroupSheet[],
  currentTab: string,
  tabName: string,
) => {
  const indexOfCurrentTab = tabs.findIndex((tab) => tab.id === currentTab);
  tabs.splice(indexOfCurrentTab + 1, 0, {
    id: DEFAULT_NEW_TAB_ID,
    name: tabName,
    tableIds: [],
  });
  return tabs;
};

export const orgDomainFilterModifications = (
  filters: ReportFilterV2,
  filterAttrConfig: FilteredAttributesConfig,
  selectedAttributeIds: string[],
  primaryLeaderLogins: string[],
) => {
  if (
    selectedAttributeIds.includes(filterAttrConfig.primaryLeaderAttrId) ||
    selectedAttributeIds.includes(filterAttrConfig.primaryLeaderInDirectsAttrId)
  ) {
    if (!selectedAttributeIds.includes(filterAttrConfig.directsAttrId)) {
      return {
        [OperatorType.And]: [
          ...filters[OperatorType.And].reduce(
            (acc: ReportFilterRule[], next) => {
              if (
                (next.In &&
                  Object.keys(next.In)[0] !==
                    filterAttrConfig.primaryLeaderAttrId) ||
                (next.Not_In &&
                  Object.keys(next.Not_In)[0] !==
                    filterAttrConfig.primaryLeaderAttrId)
              ) {
                acc.push(next);
              }
              return acc;
            },
            [],
          ),
          {
            [OperatorType.In]: {
              [filterAttrConfig.primaryLeaderInDirectsAttrId]:
                primaryLeaderLogins,
            },
          },
        ],
      };
    }
  }
  return filters;
};

export const orgDomainAttributeModifications = (
  attributeIds: string[],
  filterAttrConfig: FilteredAttributesConfig,
) => {
  // check if the attributes include the primary leader id
  if (attributeIds.includes(filterAttrConfig?.primaryLeaderAttrId)) {
    // if we have directs as well change nothing
    if (attributeIds.includes(filterAttrConfig?.directsAttrId)) {
      return attributeIds;
    }
    // if we only have leader replace the primary leader attribute id with
    // primaryLeaderInDirectsAttrId but maintain the ordering
    const leaderIndex = attributeIds.indexOf(
      filterAttrConfig?.primaryLeaderAttrId,
    );
    attributeIds.splice(
      leaderIndex,
      1,
      filterAttrConfig?.primaryLeaderInDirectsAttrId,
    );
    return attributeIds;
  }
  return [...attributeIds, filterAttrConfig?.primaryLeaderInDirectsAttrId];
};

export function getReadOnlyItems(
  domain: string,
  domainConfigs?: DomainConfigs,
): string[] {
  return [
    domainConfigs?.filteredAttributes[domain]?.primaryLeaderAttrId || '',
    domainConfigs?.filteredAttributes[domain]?.primaryLeaderInDirectsAttrId ||
      '',
    ...(domainConfigs?.config[domain]?.requiredColumns || []),
  ].filter((item) => item.length);
}

export function addBulkUpdateFilterMessage(
  addMessage: (message: Message) => string,
  updatedTableNames?: string[],
  notUpdatedTableNames?: string[],
) {
  const createMessage = (
    messagePrefix: string,
    type: MessageBannerType,
    autoDismissAfter: number,
    tableNames?: string[],
    messageSuffix?: string,
  ) => {
    if (tableNames && tableNames.length > 0) {
      const joinedTableNames = tableNames.join(', ');
      let messageText = messagePrefix + joinedTableNames;
      if (messageSuffix) {
        messageText += messageSuffix;
      }
      addMessage({
        autoDismissAfter,
        messageGroupId: MessageGroupIds.REPORT_LEVEL,
        text: messageText,
        dismissible: true,
        type,
      });
    }
  };

  createMessage(
    'Filters have been applied to ',
    MessageBannerType.Success,
    DEFAULT_SUCCESS_AUTO_DISMISS_AFTER_SEC,
    updatedTableNames,
  );
  createMessage(
    'Filters were not applied to tables of different domains ',
    MessageBannerType.Warning,
    DEFAULT_WARNING_AUTO_DISMISS_AFTER_SEC,
    notUpdatedTableNames,
  );
}

const ZERO_BADGER_T8W_ATTRIBUTE = 'A1696032871';
const INCONSISTENT_BADGER_T8W_ATTRIBUTE = 'A1697685218';

export enum SupportedGraphicalErrors {
  INCONSISTENT_BADGER_TABLE = 'INCONSISTENT_BADGERS',
  ZERO_BADGER_TABLE = 'ZERO_BADGERS',
}

export function shouldRenderGraphicalErrorPage(
  reportTemplate: ReportTemplate | undefined,
  noRowsError: boolean | undefined,
) {
  const attributeIds = extractAttributesFromReportTemplate(reportTemplate);
  const isZeroBadgerTable =
    attributeIds.includes(ZERO_BADGER_T8W_ATTRIBUTE) &&
    !attributeIds.includes(INCONSISTENT_BADGER_T8W_ATTRIBUTE);
  const isInconsistentBadgerTable =
    attributeIds.includes(INCONSISTENT_BADGER_T8W_ATTRIBUTE) &&
    !attributeIds.includes(ZERO_BADGER_T8W_ATTRIBUTE);

  const shouldRenderGraphicalError =
    noRowsError && (isZeroBadgerTable || isInconsistentBadgerTable);

  return {
    shouldRenderGraphicalError,
    whichGraphicalErrorToShow:
      (isInconsistentBadgerTable &&
        SupportedGraphicalErrors.INCONSISTENT_BADGER_TABLE) ||
      (isZeroBadgerTable && SupportedGraphicalErrors.ZERO_BADGER_TABLE) ||
      null,
  };
}

export function shouldRenderNoEmployeesErrorMessage(
  reportTemplate: ReportTemplate | undefined,
  noRowsError: boolean | undefined,
  differentNoRowErrorAttrIds: string[] | undefined,
) {
  if (!reportTemplate) {
    return false;
  }

  const attributeIds = extractAttributesFromReportTemplate(reportTemplate);
  return (
    noRowsError &&
    intersection(differentNoRowErrorAttrIds || [], attributeIds).length > 0
  );
}

export function mergeTemplateSectionChanges(
  templateSectionState: TemplateSectionItems,
  stagedChanges: TemplateSectionItems,
) {
  const result = templateSectionState.copy();
  stagedChanges.items.forEach((value, key) => {
    if (value.isDeleted) {
      result.items.delete(key);
    } else {
      result.items.set(key, value);
    }
  });
  return result;
}
